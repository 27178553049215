import {Alert, Box, Button, Grid, Group, Text, Modal, ModalProps, useMantineTheme, Stack} from "@mantine/core";
import {IconBrandAndroid, IconBrandApple, IconBrandAppleFilled, IconCheck, IconX} from "@tabler/icons-react";

import Cookies from "universal-cookie";
import { useTranslation } from "react-i18next";
import QRCode from "react-qr-code";

const cookies = new Cookies(null, { path: '/' });
const {Col} = Grid

type Props = {
    setSelectedData?: (id: string) => void;
    data?: any;
    refetchData?: () => void;
} & ModalProps;

export const ShowQRStore = ({data, ...props}: Props) => {
    const { t } = useTranslation();
    const {colors} = useMantineTheme();
    
    const closeModal = () => {
        props.onClose();
    };

    return (
        <Modal
            {...props} onClose={closeModal} size="xl" centered={true}
            styles={{
                body: {
                    background: colors.gray[0],
                    position: "relative",
                    padding: 0
                },
                header: {
                    position: "absolute",
                    background: "#ffffff00",
                    left: 0
                }
            }}
        >
            <Box p={0} h={400}>
                <Grid gutter={0} justify="center" align="center">
                    <Col span={{base: 12, md: 5}} style={{alignSelf: "center", textAlign: "center"}}>
                        <QRCode
                            size={256}
                            style={{ height: "auto", maxWidth: 200, width: "70%"}}
                            value={process.env.REACT_APP_STORE_URL as string}
                            viewBox={`0 0 256 256`}
                        />
                    </Col>
                    <Col span={{base: 12, md: 7}} h={400} bg={"#fffcf2"}>
                        <Stack justify="center" align="center" w={"100%"} h={"100%"} p={20}>
                            <Text size="30px" fw={700} ta={"center"} mb={5}>حمل تطبيقاتنا الان</Text>
                            <Text c={"gray.7"} size="14px" ta={"center"} mb={20} style={{lineHeight: 1.5}}>بعد تحميل التطبيق من المتجر الالكتروني يمكنك مسح ال QR CODE للدخول الى متجرك والتحكم فيه</Text>

                            <Group w={"100%"} justify="center">
                                <Button
                                    variant="outline" component="a" target="_blank" radius={"lg"}
                                    leftSection={<IconBrandApple size={16} />}
                                    href="https://apps.apple.com/dz/app/bi3li/id6502666375?l=ar"
                                >IOS</Button>
                                <Button
                                    variant="outline" component="a" target="_blank" radius={"lg"}
                                    leftSection={<IconBrandAndroid size={16} />}
                                    href="https://play.google.com/store/apps/details?id=com.bi3li.app&hl=ar"
                                >Android</Button>
                            </Group>
                        </Stack>
                    </Col>
                </Grid>
            </Box>
        </Modal>
    );
};