import {Alert, Autocomplete, Box, Button, CheckIcon, Grid, Group, Image, List, NumberInput, Radio, Select, Stack, Stepper, Switch, Text, TextInput} from "@mantine/core";
import {IconCheck, IconX} from "@tabler/icons-react";
import Modal, { Props as ModalProps } from "./Modal";
import { client } from "../../lib/axiosClient";
import { Notyf } from "notyf";
import { useEffect, useState } from "react";
import { notifications } from "@mantine/notifications";
// import {Notyf} from "notyf";

import Cookies from "universal-cookie";
import { useShipping } from "../../api";
import { useForm } from "@mantine/form";
import { zodResolver } from 'mantine-form-zod-resolver';
import { z } from 'zod';
import Wilayas from '../../helper/wilayas.json';
import Communes from '../../helper/communes.json';
import axios from "axios";
import { useTranslation } from "react-i18next";

const cookies = new Cookies(null, { path: '/' });
const {Col} = Grid

type Props = {
    setSelectedData?: (id: string) => void;
    data?: any;
    refetchData?: () => void;
} & ModalProps;


export const ChangeStatusOrderWithoutMulti = ({data, ...props}: Props) => {
    const { t } = useTranslation();
    const [loading, setLoading] = useState(false);

    const onFormSubmit = () => {
        setLoading(true)

        client.put(`/orders/${data?.id}`, {
            addToDeliveryCompany: false,
            deliveryCompany: "",
            "status": data?.status
        }, {
            headers: {
                'Accept': 'application/json',
                'Authorization': cookies.get('token') || ""
            }
        })
        .then(({data}) => {
            notifications.show({ message: t('modals.changeStatusOrder.alert01'), color: '#fff' });
            setLoading(false)
            typeof props?.refetchData == "function" && props?.refetchData()
            closeModal()
        })
        .catch((error) => {
            notifications.show({ message: t('modals.changeStatusOrder.alert02'), color: '#fff' });
            setLoading(false)
            closeModal()
        });
    };

    const closeModal = () => {
        props.onClose();
        setLoading(false)
    };

    return (
        <Modal
            {...props} onClose={closeModal} loading={loading} size="lg"
            footer={
                <Box py={16} px={20} bg="slate.0"><Group justify={"flex-end"} gap={"xs"}>
                    <Button color={'gray'} variant="outline" rightSection={<IconX size={15} />} bg="white" onClick={closeModal}>{t('modals.cancelling')}</Button>
                    <Button rightSection={<IconCheck size={15} />} onClick={onFormSubmit} type={"button"} >
                        {data?.status === "confirmed" 
                            ? t('modals.changeStatusOrder.labelButton01')
                            : data?.status === "delivered" ? "توصيل" 
                            : data?.status === "retrieved" ? "استرجاع" 
                            : t('modals.changeStatusOrder.labelButton02')
                        }
                    </Button>
                </Group>
                </Box>
            }
            title={
                data?.status === "confirmed" ? t('modals.changeStatusOrder.title01') 
                    : data?.status === "delivered" ? "توصيل الطلب" 
                    : data?.status === "retrieved" ? "استرجاع الطلب" 
                    : t('modals.changeStatusOrder.title02')
            }
        >
            <Box style={({ colors }) => ({padding: 20})}>
                <Grid gutter={20}>
                    <Col span={12} >
                        <Alert color={data?.status === "confirmed" ? "blue" : data?.status === "delivered" ? "green" : "red"}>
                            {t('modals.changeStatusOrder.text01')}{" "}
                            {data?.status === "confirmed" 
                                ? t('modals.changeStatusOrder.labelButton01')
                                : data?.status === "delivered" ? "توصيل" 
                                : data?.status === "retrieved" ? "استرجاع" 
                                : t('modals.changeStatusOrder.labelButton02')
                            }{" "}
                            {t('modals.changeStatusOrder.text02')}
                        </Alert>
                    </Col>
                </Grid>
            </Box>
        </Modal>
    );
};